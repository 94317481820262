<template>
  <b-tabs>
    <b-tab :title="getCodeLabel('company_type',type)+'信息'" active>
      <b-card
          class="company-edit-wrapper"
      >
        <!-- form -->
        <b-form id="companyForm" class="edit-form mt-2">
          <b-row>
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="创建组织"
                  label-for="company_owner"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="company_owner"
                    size="sm"
                    v-model="company.company_owner"
                    readonly
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="注册编码"
                  label-for="register_code"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="register_code"
                    size="sm"
                    v-model="company.register_code"
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="公司编码"
                  label-for="company_code"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="company_code"
                    size="sm"
                    v-model="company.company_code"
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="名称"
                  label-for="company_name"
                  label-size="sm"
                  class="mb-1 required"
              >
                <b-form-input
                    id="company_name"
                    size="sm"
                    v-model="company.company_name"
                    :readonly="type==6 && id!=0 && company_status !=1 && company_status !=3"
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="简称"
                  label-for="nick_name"
                  label-size="sm"
                  class="mb-1 required"
              >
                <b-form-input
                    id="nick_name"
                    size="sm"
                    v-model="company.nick_name"
                    :readonly="type==6 && id!=0 && company_status !=1 && company_status !=3"
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="拼音首字母"
                  label-for="first_letter"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="first_letter"
                    size="sm"
                    v-model="company.first_letter"
                    readonly
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="公司固定电话"
                  label-for="telephone"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="telephone"
                    size="sm"
                    v-model="company.telephone"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="邮箱"
                  label-for="email"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="email"
                    size="sm"
                    v-model="company.email"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="传真"
                  label-for="fax"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="fax"
                    size="sm"
                    v-model="company.fax"
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="ERP编码"
                  label-for="fax"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="erp_code"
                    size="sm"
                    v-model="company.erp_code_no"
                />
              </b-form-group>
            </b-col>

            <b-col md="4" v-if="type==3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="买家ID"
                  label-for="fax"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="bbm_buyer_id"
                    size="sm"
                    v-model="company.bbm_buyer_id"
                />
              </b-form-group>
            </b-col>

            <b-col md="4" v-if="type==4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="中台仓库类型"
                  label-for="fax"
                  label-size="sm"
                  class="mb-1"
              >
                <v-select
                    id="zt_warehouse_type"
                    :options="getCodeOptions('zt_warehouse_type')"
                    v-model="ztWarehouseType"
                    @input="changeSelect('zt_warehouse_type',$event)"
                    :clearable="true"
                    class="select-size-sm"
                    placeholder="请选择中台仓库类型"
                />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <div class="inner-card-title">基本信息</div>
            </b-col>
            <b-col md="4">

              <b-card header="地址">
                <b-row>
                  <b-col md="12">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="地理分区"
                        label-for="region_area"
                        label-size="sm"
                        class="mb-1"
                    >
                      <v-select
                          id="region_area"
                          :options="getCodeOptions('region_area')"
                          v-model="regionArea"
                          @input="changeSelect('region_area',$event)"
                          :clearable="true"
                          class="select-size-sm"
                          placeholder="请选择地理分区"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <area-select v-if="company.loaded"
                                 :provinceId="province_id"
                                 :cityId="city_id"
                                 :areaId="area_id"
                                 ref="areaSel"
                                 :level="level"
                    />
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="通讯地址"
                        label-for="address"
                        label-size="sm"
                        class="mb-1"
                    >
                      <b-form-input
                          id="address"
                          size="sm"
                          v-model="company.address"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="邮政编码"
                        label-for="zipcode"
                        label-size="sm"
                        class="mb-1"
                    >
                      <b-form-input
                          id="zipcode"
                          size="sm"
                          v-model="company.zipcode"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="语言"
                        label-for="language"
                        label-size="sm"
                        class="mb-1"
                    >
                      <v-select
                          id="language"
                          :options="getCodeOptions('company_lang')"
                          v-model="language"
                          @input="changeSelect('language',$event)"
                          :clearable="true"
                          class="select-size-sm"
                          placeholder="请选择语言"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="公司网址"
                        label-for="web_site"
                        label-size="sm"
                        class="mb-1"
                    >
                      <b-form-input
                          id="web_site"
                          size="sm"
                          v-model="company.web_site"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>

            </b-col>

            <!--yang 注册-->
            <b-col md="4">
              <b-card header="注册">
                <b-row>
                  <b-col md="12">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="公司行业"
                        label-for="industry"
                        label-size="sm"
                        class="mb-1"
                    >
                      <v-select
                          id="industry"
                          :options="getCodeOptions('industry_type')"
                          v-model="industry"
                          @input="changeSelect('industry',$event)"
                          :clearable="true"
                          class="select-size-sm"
                          placeholder="请选择公司行业"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="注册日期"
                        label-for="registration_date"
                        label-size="sm"
                        class="mb-1"
                    >
                      <flat-pickr
                          v-model="company.registration_date"
                          class="form-control"
                          :config="{enableTime: true,dateFormat: 'Y-m-d H:i'}"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="法人"
                        label-for="corporation"
                        label-size="sm"
                        class="mb-1"
                    >
                      <b-form-input
                          id="corporation"
                          size="sm"
                          v-model="company.corporation"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="注册资金(万)"
                        label-for="registered_capital"
                        label-size="sm"
                        class="mb-1"
                    >
                      <b-form-input
                          id="registered_capital"
                          size="sm"
                          v-model="company.registered_capital"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="工商登记号"
                        label-for="business_registration"
                        label-size="sm"
                        class="mb-1"
                    >
                      <b-form-input
                          id="business_registration"
                          size="sm"
                          v-model="company.business_registration"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="生产经营许可证"
                        label-for="business_certificate"
                        label-size="sm"
                        class="mb-1"
                    >
                      <b-form-input
                          id="business_certificate"
                          size="sm"
                          v-model="company.business_certificate"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="统一社会信用代码"
                        label-for="uniform_social_credit_code"
                        label-size="sm"
                        class="mb-1 required"
                    >
                      <b-form-input
                          id="uniform_social_credit_code"
                          size="sm"
                          v-model="company.uniform_social_credit_code"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="注册地址"
                        label-for="register_address"
                        label-size="sm"
                        class="mb-1"
                    >
                      <b-form-input
                          id="register_address"
                          size="sm"
                          v-model="company.register_address"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>

            <!--yang 管理-->
            <b-col md="4">
              <b-card header="管理">
                <b-row>
                  <!--负责任-->
                  <b-col md="12">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="负责人"
                        label-for="user_id"
                        label-size="sm"
                        class="mb-1 required"
                    >
                      <b-form-input
                          id="user_id"
                          size="sm"
                          v-model="userId"
                          @input="changeUserId()"
                          v-b-modal.modal-select-user
                          placeholder="点击搜索负责人"
                      />
                      <!--                      @input="contractNo"-->
                    </b-form-group>
                  </b-col>
                  <!--负责人 modal-->
                  <b-modal
                      id="modal-select-user"
                      ok-only
                      ok-title="确认"
                      @ok="onSelectUser"
                      cancel-title="取消"
                      centered
                      size="lg"
                      title="选择姓名"
                  >
                    <user-select
                        ref="myUserSelect" :userDepartment="userDepartment">
                    </user-select>

                  </b-modal>

                  <!--单位类型 type=6 终端客户-->
                  <b-col md="12" v-if="type===6">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="单位类型"
                        label-for="company_unit_type"
                        label-size="sm"
                        class="mb-1 required"
                    >
                      <v-select
                          id="company_unit_type"
                          :options="getCodeOptions('company_unit_type')"
                          :clearable="true"
                          v-model="companyUnitType"
                          @input="changeSelect('company_unit_type',$event)"
                          class="select-size-sm"
                          placeholder="请选择单位类型"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- type=3 供应商分类-->
                  <b-col md="12" v-if="type==3">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="供应商分类"
                        label-for="company_class"
                        label-size="sm"
                        class="mb-1 required"
                    >
                      <v-select
                          id="company_class"
                          :options="getCodeOptions('company_class')"
                          :clearable="true"
                          v-model="companyClass"
                          @input="changeSelect('company_class',$event)"
                          class="select-size-sm"
                          placeholder="请选择供应商分类"
                      />
                    </b-form-group>
                  </b-col>

                  <!--店铺名称-->
                  <b-col md="12">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="店铺名称"
                        label-for="store_name"
                        label-size="sm"
                        class="mb-1"
                    >
                      <b-form-input
                          id="store_name"
                          size="sm"
                          v-model="company.store_name"
                          v-b-modal.modal-select-store
                          placeholder="点击搜索店铺名称"
                      />
                    </b-form-group>
                  </b-col>
                  <!--店铺名称 modal-->
                  <b-modal
                      id="modal-select-store"
                      ok-only
                      ok-title="确认"
                      @ok="onSelectStore"
                      cancel-title="取消"
                      centered
                      size="lg"
                      title="选择店铺"
                  >
                    <store-list ref="storeList" :store_id="company.store_id" :store_name="company.store_name"/>
                  </b-modal>

                  <!--供应类别-->
                  <b-col md="12">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="供应类别"
                        label-for="supply_class"
                        label-size="sm"
                        :class="`mb-1 ${type == '6' ? '' : 'required'}`"
                    >
                      <v-select
                          id="supply_class"
                          :options="getCodeOptions('supply_class')"
                          :clearable="true"
                          v-model="supplyClass"
                          @input="changeSelect('supply_class',$event)"
                          class="select-size-sm"
                          placeholder="请选择供应商类别"
                      />
                    </b-form-group>
                  </b-col>

                  <!--公司等级-->
                  <b-col md="12">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="公司等级"
                        label-for="company_level"
                        label-size="sm"
                        :class="`mb-1 ${type == '6' ? '' : 'required'}`"
                    >
                      <v-select
                          id="company_level"
                          :options="getCodeOptions('company_level')"
                          :clearable="true"
                          v-model="companyLevel"
                          @input="changeSelect('company_level',$event)"
                          class="select-size-sm"
                          placeholder="请选择公司等级"
                      />
                    </b-form-group>
                  </b-col>

                  <!--公司性质-->
                  <b-col md="12">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="公司性质"
                        label-for="company_properties"
                        label-size="sm"
                        :class="`mb-1 ${type == '6' ? '' : 'required'}`"
                    >
                      <v-select
                          id="company_properties"
                          :options="getCodeOptions('company_properties')"
                          :clearable="true"
                          v-model="companyProperties"
                          @input="changeSelect('company_properties',$event)"
                          class="select-size-sm"
                          placeholder="请选择公司性质"
                      />
                    </b-form-group>
                  </b-col>

                  <!--公司类别-->
                  <b-col md="12">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        :label=company_kind_label
                        label-for="company_kind"
                        label-size="sm"
                        class="mb-1"
                    >
                      <v-select
                          id="company_kind"
                          :options="optionsType(type)"
                          :clearable="true"
                          v-model="companyKind"
                          @input="changeSelect('companyKind',$event)"
                          class="select-size-sm"
                          placeholder="请选择公司类别"
                      />
                    </b-form-group>
                  </b-col>

                  <!--公司规模-->
                  <b-col md="12">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="公司规模"
                        label-for="company_scale"
                        label-size="sm"
                        class="mb-1"
                    >
                      <v-select
                          id="company_scale"
                          :options="getCodeOptions('company_scale')"
                          :clearable="true"
                          v-model="companyScale"
                          @input="changeSelect('company_scale',$event)"
                          class="select-size-sm"
                          placeholder="请选择公司规模"
                      />
                    </b-form-group>
                  </b-col>

                  <!--对应组织-->
                  <b-col md="12">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="对应组织"
                        label-for="company_organization"
                        label-size="sm"
                        class="mb-1"
                    >
                      <b-form-input
                          id="company_organization"
                          size="sm"
                          v-model="company.company_organization"
                          readonly
                      />
                    </b-form-group>
                  </b-col>


                </b-row>
              </b-card>
            </b-col>

            <b-col md="12">
              <b-card header="开票信息">
                <b-row>
                  <b-col md="4">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="是否开票"
                        label-for="is_invoice"
                        label-size="sm"
                        class="mb-1"
                    >
                      <b-form-checkbox
                          name="is_invoice"
                          switch
                          inline
                          id="is_invoice"
                          v-model="company.is_invoice"
                          value="1"
                          unchecked-value="0"
                          @input="changeLabel"
                      ></b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        :label=invoice_head_label
                        label-for="invoice_head"
                        label-size="sm"
                        class="mb-1"
                    >
                      <b-form-input
                          id="invoice_head"
                          size="sm"
                          v-model="company.invoice_head"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        :label=invoice_tax_label
                        label-for="invoice_tax"
                        label-size="sm"
                        class="mb-1"
                    >
                      <b-form-input
                          id="invoice_tax"
                          size="sm"
                          v-model="company.invoice_tax"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label='地址及电话'
                        label-for="invoice_link_address"
                        label-size="sm"
                        class="mb-1"
                    >
                      <b-form-input
                          id="invoice_link_address"
                          size="sm"
                          placeholder="普票可不填"
                          v-model="company.invoice_link_address"
                      />
                    </b-form-group>
                  </b-col>
<!--                  <b-col md="4">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label='开票联系电话'
                        label-for="invoice_link_tel"
                        label-size="sm"
                        class="mb-1"
                    >
                      <b-form-input
                          id="invoice_link_tel"
                          size="sm"
                          v-model="company.invoice_link_tel"
                      />
                    </b-form-group>
                  </b-col>-->
                  <b-col md="4">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label='银行及账号'
                        label-for="invoice_bank_name"
                        label-size="sm"
                        class="mb-1"
                    >
                      <b-form-input
                          id="invoice_bank_name"
                          size="sm"
                          placeholder="普票可不填"
                          v-model="company.invoice_bank_name"
                      />
                    </b-form-group>
                  </b-col>
<!--                  <b-col md="4">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label='银行账号'
                        label-for="invoice_bank_no"
                        label-size="sm"
                        class="mb-1"
                    >
                      <b-form-input
                          id="invoice_bank_no"
                          size="sm"
                          v-model="company.invoice_bank_no"
                      />
                    </b-form-group>
                  </b-col>-->
                </b-row>
              </b-card>
            </b-col>

            <b-col md="12" v-if="type == '3'">
              <b-card header="风控">
                <b-row>
                  <b-col md="4">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="企业实控人"
                        label-for="actual_controller"
                        label-size="sm"
                        class="mb-1 required"
                    >
                      <b-form-input
                          id="actual_controller"
                          size="sm"
                          v-model="company.actual_controller"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="实控人身份证号"
                        label-for="actual_card"
                        label-size="sm"
                        class="mb-1 required"
                    >
                      <b-form-input
                          id="actual_card"
                          size="sm"
                          v-model="company.actual_card"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="实控人手机号"
                        label-for="actual_phone"
                        label-size="sm"
                        class="mb-1 required"
                    >
                      <b-form-input
                          id="actual_phone"
                          size="sm"
                          v-model="company.actual_phone"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col md="12">
              <div class="inner-card-title">附件</div>
            </b-col>

            <b-col md="12">
              <b-form-group
                  label-cols="1"
                  label-cols-lg="1"
                  label="营业执照扫描件"
                  label-for="attachments_USCC_id"
                  label-size="sm"
                  :class="`mb-1 ${type == '6' ? '' : 'required'}`"
              >
                <attachment-upload v-if="company.loaded" :theme="'files'"
                                   :attachment_id="'attachments_uscc_id'"
                                   :id="company.attachments_uscc_id"
                                   :object_type="'company'"
                                   :object_id="0"
                                   @change="onUploaded"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                  label-cols="1"
                  label-cols-lg="1"
                  label="周年报表/存续证明"
                  label-for="attachments_GSC_id"
                  label-size="sm"
                  class="mb-1"
              >
                <attachment-upload v-if="company.loaded" :theme="'files'"
                                   :attachment_id="'attachments_gsc_id'"
                                   :id="company.attachments_gsc_id"
                                   :object_type="'company'"
                                   :object_id="0"
                                   @change="onUploaded"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                  label-cols="1"
                  label-cols-lg="1"
                  label="法人身份证/护照"
                  label-for="attachments_IDCard"
                  label-size="sm"
                  class="mb-1"
              >
                <attachment-upload v-if="company.loaded" :theme="'files'"
                                   :attachment_id="'attachments_idcard'"
                                   :id="company.attachments_idcard"
                                   :object_type="'company'"
                                   :object_id="0"
                                   @change="onUploaded"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                  label-cols="1"
                  label-cols-lg="1"
                  label="产品链路证明"
                  label-for="attachments_link"
                  label-size="sm"
                  :class="`mb-1 ${type == '6' ? '' : 'required'}`"
              >
                <attachment-upload v-if="company.loaded" :theme="'files'"
                                   :attachment_id="'attachments_link'"
                                   :id="company.attachments_link"
                                   :object_type="'company'"
                                   :object_id="0"
                                   @change="onUploaded"
                />
                <!--                :class="type==3?'mb-1 required':'mb-1'"-->
                <!--            <b-form-input-->
                <!--                id="attachments_link"-->
                <!--                size="sm"-->
                <!--                v-model="company.attachments_link"-->
                <!--                readonly-->
                <!--            />-->
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                  label-cols="1"
                  label-cols-lg="1"
                  label="历史交易资料"
                  label-for="attachments_history"
                  label-size="sm"
                  class="mb-1"
              >
                <attachment-upload v-if="company.loaded" :theme="'files'"
                                   :attachment_id="'attachments_history'"
                                   :id="company.attachments_history"
                                   :object_type="'company'"
                                   :object_id="0"
                                   @change="onUploaded"
                />
                <!--            <b-form-input-->
                <!--                id="attachments_history"-->
                <!--                size="sm"-->
                <!--                v-model="company.attachments_history"-->
                <!--                readonly-->
                <!--            />-->
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                  label-cols="1"
                  label-cols-lg="1"
                  label="其他资料"
                  label-for="attachments_other"
                  label-size="sm"
                  class="mb-1"
              >
                <attachment-upload v-if="company.loaded" :theme="'files'"
                                   :attachment_id="'attachments_other'"
                                   :id="company.attachments_other"
                                   :object_type="'company'"
                                   :object_id="0"
                                   @change="onUploaded"
                />
                <!--            <b-form-input-->
                <!--                id="attachments_other"-->
                <!--                size="sm"-->
                <!--                v-model="company.attachments_other"-->
                <!--                readonly-->
                <!--            />-->
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                  label-cols="1"
                  label-cols-lg="1"
                  label="附件"
                  label-for="attachments"
                  label-size="sm"
                  class="mb-1"
              >
                <attachment-upload v-if="company.loaded" :theme="'files'"
                                   :attachment_id="'attachments'"
                                   :id="company.attachments"
                                   :object_type="'company'"
                                   :object_id="0"
                                   @change="onUploaded"
                />
                <!--            <b-form-input-->
                <!--                id="attachments"-->
                <!--                size="sm"-->
                <!--                v-model="company.attachments"-->
                <!--                readonly-->
                <!--            />-->
              </b-form-group>
            </b-col>
            <b-col
                cols="12"
                class="mt-50"
            >
              <b-button
                  variant="primary"
                  class="mr-1"
                  @click="save"
              >
                保存
              </b-button>
              <b-button
                  variant="outline-secondary"
                  @click="cancel"
              >
                返回
              </b-button>
            </b-col>

          </b-row>
        </b-form>
        <!--/ form -->
      </b-card>
    </b-tab>

    <!--    <b-tab title="联系人信息" v-if="showTab">-->
    <!--      <contact-edit :company_id="company.company_id">-->
    <!--      </contact-edit>-->
    <!--    </b-tab>-->

    <!--yang-->
    <b-tab title="联系人信息" v-if="showTab">
<!--      <contact-list :company_id="company.company_id" :toReturnBack="1">
      </contact-list>-->
      <contact-new :company_id="company.company_id"></contact-new>
    </b-tab>

    <!--    <b-tab title="银行账户信息" v-if="showTab">-->
    <!--      <bank-account-edit :company_id="company.company_id">-->
    <!--      </bank-account-edit>-->
    <!--    </b-tab>-->


    <b-tab title="银行账户信息" v-if="showTab">
<!--      <bank-account-list :company_id="company.company_id" :toReturnBack="1">
      </bank-account-list>-->
      <bak-acc-new :company_id="company.company_id"></bak-acc-new>
    </b-tab>

    <b-tab title="预付款信息" v-if="showTab&&company.company_type===3&&user.role_id===14">
      <company-prepaid-edit :company_id="company.company_id">
      </company-prepaid-edit>
    </b-tab>

    <b-tab title="采购协议信息" v-if="showTab&&company.company_type===3">
      <contract-list :company_id="company.company_id" :contract_type="1">
      </contract-list>
    </b-tab>

    <!--    <b-tab title="仓库信息" v-if="showTab&&company.company_type===4">-->
    <!--      <warehouse-edit :company_id="company.company_id" :company_name="company.company_name">-->
    <!--      </warehouse-edit>-->
    <!--    </b-tab>-->

    <b-tab title="仓库信息" v-if="showTab&&company.company_type===4">
      <warehouse-list :company_id="company.company_id" :company_name="company.company_name">
      </warehouse-list>
    </b-tab>

    <!--    <b-tab title="部门信息" v-if="showTab&&company.company_type===2&&user.role_id===14">-->
    <!--      <department-edit :company_id="company.company_id">-->
    <!--      </department-edit>-->
    <!--    </b-tab>-->

    <b-tab title="部门信息" v-if="showTab&&company.company_type===2&&user.role_id===14">
      <department-list :company_id="company.company_id">
      </department-list>
    </b-tab>

    <!--账期信息-->
    <b-tab title="账期信息" v-if="company.company_type===6">
      <warehouse-statement-list :company_id="company.company_id">
      </warehouse-statement-list>
    </b-tab>


    <b-col
        cols="12"
        class="mt-50"
    >
      <b-button
          variant="info"
          class="mr-1"
          @click="go"
      >
        返回列表页
      </b-button>
    </b-col>
  </b-tabs>


</template>

<script>
import {
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BCalendar,
  BFormDatepicker,
  BCardBody,
  BCardGroup,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {computed, onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import companyStore from './companyStore'
import userStore from "@/views/apps/user/userStore";
import {getUserData} from '@/auth/utils'
// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor, second, isEmpty} from '@core/utils/filter'
import UserSelect from '@/views/apps/user/user-modal/UserSelect'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import flatPickr from 'vue-flatpickr-component'
import areaStore from "@/views/apps/area/areaStore";
import AreaSelect from "@/views/apps/area/AreaSelect";
import ContactEdit from "@/views/apps/contact/ContactEdit";
import BankAccountEdit from "@/views/apps/bankaccount/BankAccountEdit";
import CompanyPrepaidEdit from "@/views/apps/companyprepaid/CompanyPrepaidEdit";
import WarehouseEdit from "@/views/apps/warehouse/WarehouseEdit";
import DepartmentEdit from "@/views/apps/department/DepartmentEdit";
import ContractList from "@/views/apps/contract/ContractList";
import ContactList from "@/views/apps/contact/ContactList";
import BankAccountList from "@/views/apps/bankaccount/BankAccountList";
import WarehouseList from "@/views/apps/warehouse/WarehouseList";
import DepartmentList from "@/views/apps/department/DepartmentList";
import WarehouseStatementList from "@/views/apps/warehousestatement/WarehouseStatementList";
import StoreList from "@/views/apps/store/store-modal/StoreListMutiSelect";
import ContactNew from "@/views/apps/contact/ContactNew";
import BakAccNew from "@/views/apps/bankaccount/BakAccNew";


export default {
  components: {
    BakAccNew,
    ContactNew,
    WarehouseStatementList,
    DepartmentList,
    WarehouseList,
    BankAccountList,
    ContactList,
    ContractList,
    DepartmentEdit,
    WarehouseEdit,
    CompanyPrepaidEdit,
    BankAccountEdit,
    ContactEdit,
    AreaSelect,
    UserSelect,
    StoreList,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    BCalendar,
    BFormDatepicker,
    BCardBody, BCardGroup,
    flatPickr,
    AttachmentUpload,
    BTabs,
    BTab,
  },
  data() {
    return {
      id: ref(0),
      company: ref({}),
      type: ref(0),

      province_id: '',
      city_id: '',
      area_id: '',
      level: 3,     // 省市区级别 2，3

      companyType: {},
      supplyClass: {},
      regionArea: {},
      language: {},
      industry: {},
      companyClass: {},
      companyUnitType: {},
      companyLevel: {},
      companyProperties: {},
      companyScale: {},
      status: {},
      state: {},
      registrationDate: {},
      companyKind: {},
      user: {},
      userId: "",
      ztWarehouseType:{},

      showTab: false,
      userDepartment: "",
      invoice_head_label: '公司抬头',
      invoice_tax_label: '纳税人识别号',
      company_kind_label:'公司类别',
      company_status:''
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('company')) store.registerModule('company', companyStore)
    if (!store.hasModule('user')) store.registerModule('user', userStore)
    if (!store.hasModule('area')) store.registerModule('area', areaStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('company')) store.unregisterModule('company')
      if (store.hasModule('user')) store.unregisterModule('user')
      if (store.hasModule('area')) store.unregisterModule('area')
    })

    const edit = function () {
      store.dispatch('company/edit', {id: this.id}).then(res => {
        this.company = res.data.data
        this.company.store_id = this.company.ext.store_id
        this.company.store_name = this.company.ext.store_name
        this.company_status = this.company.status
        //yang
        // 判断是新增还是编辑
        if (this.id != 0) {
          this.province_id = this.company.province_id
          this.city_id = this.company.city_id
          this.area_id = this.company.area_id
          this.type = this.company.company_type
          this.showTab = true
        }
        this.company.company_owner = 0
        this.company.company_organization = 0
        this.company.status = 4
        this.company.state = 1
        if (this.company.user_id === undefined) {
          this.userId = this.user.full_name
          this.company.user_id = this.user.user_id
          store.dispatch('user/search', {user_id: this.user.user_id}).then(res => {
            this.company.deparment_id = res.data.data.list[0].deptartment_id
          })
        } else {
          this.userId = getCodeLabel("user", this.company.user_id)
        }
        this.companyType = getCode('company_type', this.company.company_type)
        this.supplyClass = getCode('supply_class', this.company.supply_class)
        this.regionArea = getCode('region_area', this.company.region_area)
        this.language = getCode('company_lang', this.company.language)
        this.industry = getCode('industry_type', this.company.industry)
        this.companyClass = getCode('company_class', this.company.company_class)
        this.companyUnitType = getCode('company_unit_type', this.company.company_unit_type)
        this.companyLevel = getCode('company_level', this.company.company_level)
        this.companyProperties = getCode('company_properties', this.company.company_properties)
        this.companyScale = getCode('company_scale', this.company.company_scale)
        this.status = getCode('company_status', this.company.status)
        this.state = getCode('state', this.company.state)
        this.company.registration_date = toDate(this.company.registration_date)
        this.companyKind = getCode('company_kind_GYS', this.company.company_kind)
        this.ztWarehouseType = getCode('zt_warehouse_type',this.company.zt_warehouse_type)
        if (this.type === "1" || this.type === 1) {
          this.companyKind = getCode('company_kind_QDS', this.company.company_kind)
        }
        if (this.type === "2" || this.type === 2) {
          this.companyKind = null
        }
        if (this.type === "3" || this.type === 3) {
          this.companyKind = getCode('company_kind_GYS', this.company.company_kind)
        }
        if (this.type === "4" || this.type === 4) {
          this.companyKind = null
        }
        if (this.type === "5" || this.type === 5) {
          this.companyKind = null
        }
        if (this.type === "6" || this.type === 6) {
          this.companyKind = getCode('company_kind_ZDKH', this.company.company_kind)
        }
      })
    }

    const onSelectUser = function (bvModalEvt) {
      let checked = this.$refs.myUserSelect.getSelected()[0];
      this.userId = checked.full_name
      this.company.user_id = checked.user_id
      this.company.deparment_id = checked.deptartment_id

      // console.log(this.items)
    }

    const optionsType = function (res) {
      if (res === "1" || res === 1) {
        return getCodeOptions('company_kind_QDS')
      }
      if (res === "2" || res === 2) {
        return []
      }
      if (res === "3" || res === 3) {
        return getCodeOptions('company_kind_GYS')
      }
      if (res === "4" || res === 4) {
        return []
      }
      if (res === "5" || res === 5) {
        return []
      }
      if (res === "6" || res === 6) {
        return getCodeOptions('company_kind_ZDKH')
      }
    }

    const view = function () {
      store.dispatch('company/view', {id: this.id}).then(res => {
        this.company = res.data.data
      })
    }

    const cancel = function () {
      this.$router.go(-1)
    }

    const changeUserId = function (event) {

    }

    const save = function () {
      let result = true;


      // 将CompanyArea组件中的id传到CompanyEdit中保存
      this.company.province_id = this.$refs.areaSel.province
      this.company.city_id = this.$refs.areaSel.city
      this.company.area_id = this.$refs.areaSel.area
      if (this.type === "1" || this.type === 1) {
        this.company.company_type = 1
      }
      if (this.type === "2" || this.type === 2) {
        this.company.company_type = 2
      }
      if (this.type === "3" || this.type === 3) {
        this.company.company_type = 3
      }
      if (this.type === "4" || this.type === 4) {
        this.company.company_type = 4
      }
      if (this.type === "5" || this.type === 5) {
        this.company.company_type = 5
      }
      if (this.type === "6" || this.type === 6) {
        this.company.company_type = 6
      }
      if (this.type === "7" || this.type === 7) {
        this.company.company_type = 7
      }

      if (this.company.company_type === 3) {
        if (this.company.actual_controller === undefined || this.company.actual_controller === "") {
          toast.error("请填写 企业实控人!")
          result = false
        }
        if (this.company.actual_card === undefined || this.company.company_name === "") {
          toast.error("请填写 实控人身份证号!")
          result = false
        }
        if (this.company.actual_phone === undefined || this.company.company_name === "") {
          toast.error("请填写 实控人手机号!")
          result = false
        }
        if (this.company.actual_card.length !== 18) {
          toast.error("实控人身份证号格式不对")
          result = false
        }
        if (this.company.actual_phone.length !== 11 || !this.company.actual_phone.startsWith("1")) {
          toast.error("实控人手机号格式不对!")
          result = false
        }
      }

      if (this.company.company_name === undefined || this.company.company_name === "") {
        toast.error("请填写 公司名称!")
        result = false
      }
      if (this.company.nick_name === undefined || this.company.nick_name === "") {
        toast.error("请填写 公司简称!")
        result = false
      }
      if (this.company.uniform_social_credit_code === undefined || this.company.uniform_social_credit_code === "") {
        toast.error("请填写 统一社会信用代码!")
        result = false
      }
      if (this.userId === undefined || this.userId === "") {
        toast.error("请填写 负责人!")
        result = false
      }
      if (this.type == 3) {
        if (this.company.company_class === undefined || this.company.company_class === "") {
          toast.error("请填写 供应商分类!")
          result = false
        }
      }
      if (this.type != 6) {
        if (this.company.supply_class === undefined || this.company.supply_class === "") {
          toast.error("请填写 供应类别!")
          result = false
        }
        if (this.company.company_level === undefined || this.company.company_level === "") {
          toast.error("请填写 公司等级!")
          result = false
        }
        if (this.company.company_properties === undefined || this.company.company_properties === "") {
          toast.error("请填写 公司性质!")
          result = false
        }
        if (this.company.attachments_uscc_id === undefined || this.company.attachments_uscc_id === "") {
          toast.error("请填写 营业执照扫描件!")
          result = false
        }
      }

      if (this.type == 6) {
        if (this.company.company_unit_type === undefined || this.company.company_unit_type === "") {
          toast.error("请选择 单位类型!")
          result = false
        }
        if (isEmpty(this.company.store_id)){
          toast.error("请选择 店铺名称!")
          result = false
        }
      }

      if (this.type == 3) {
        if (this.company.attachments_link === undefined || this.company.attachments_link === "") {
          toast.error("请填写 产品链路证明!")
          result = false
        }
      }

      if (this.company.is_invoice == 1 && isEmpty(this.company.invoice_head)) {
        toast.error("请填写 公司抬头!")
        result = false
      }
      if (this.company.is_invoice == 1 && isEmpty(this.company.invoice_tax)) {
        toast.error("请填写 纳税人识别号!")
        result = false
      }
      if (this.type == 6 && isEmpty(this.companyKind)) {
        toast.error("请选择 公司类别!")
        result = false
      }
      if (this.type == 4 && isEmpty(this.ztWarehouseType)) {
        toast.error("请选择 中台仓库类型!")
        result = false
      }
      console.log(this.company)
      if (result) {
        this.company.registration_date = second(this.company.registration_date)
        store.dispatch('company/save', this.company).then(res => {
          if (res.data.code == 0) {
            toast.success('数据已保存,可进行其他操作!')
            this.company.company_id = res.data.data.company_id
            this.company.company_name = res.data.data.company_name
            this.showTab = true
          } else {
            toast.error(res.data.data)
          }
        })
      }

    }

    const go = function () {
      if (this.type === "1" || this.type === 1) {
        this.$router.push({name: 'apps-company-channel-list', query: {type: 1}});
      }
      if (this.type === "2" || this.type === 2) {
        this.$router.push({name: 'apps-company-branch-list', query: {type: 2}});
      }
      if (this.type === "3" || this.type === 3) {
        this.$router.push({name: 'apps-company-supplier-list', query: {type: 3}});
      }
      if (this.type === "4" || this.type === 4) {
        this.$router.push({name: 'apps-company-warehouse-list', query: {type: 4}});
      }
      if (this.type === "5" || this.type === 5) {
        this.$router.push({name: 'apps-company-logistics-list', query: {type: 5}});
      }
      if (this.type === "6" || this.type === 6) {
        this.$router.push({name: 'apps-company-channel-list', query: {type: 6}});
      }
      if (this.type === "7" || this.type === 7) {
        this.$router.push({name: 'apps-company-load-list', query: {type: 7}});
      }
    }

    const changeSelect = function (key, event) {
      if (event != null) {
        this.company[key] = event.value
      } else {
        this.company[key] = undefined
      }
    }

    // id：上传组件的attachment_id
    // attachment：上传文件信息
    const onUploaded = function (id, attachment, result) {
      this.company[id] = result
    }

    const changeLabel = function () {
      if (this.company.is_invoice == 1) {
        this.invoice_head_label = '公司抬头*'
        this.invoice_tax_label = '纳税人识别号*'
      } else {
        this.invoice_head_label = '公司抬头'
        this.invoice_tax_label = '纳税人识别号'
      }
    }

    const onSelectStore = function () {
      let store_id_arr = []
      let store_name_arr = []
      let select = this.$refs.storeList.getSelected()
      for (let i = 0; i < select.length; i++) {
        store_id_arr.push(select[i].store_id)
        store_name_arr.push(select[i].store_name)
      }
      this.company.store_name = store_name_arr.toString()
      this.company.store_id = store_id_arr.toString()
      this.$forceUpdate()
    }

    return {
      edit,
      view,
      cancel,
      save,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      isEmpty,

      changeSelect,
      toDate,
      toTime,
      second,
      optionsType,
      onSelectUser,
      onSelectStore,
      changeUserId,
      onUploaded,
      go,
      changeLabel,
    }
  },
  watch: {
    $route(to, from) {
      this.type = this.$route.query.type
      if (this.type == 3) {
        this.userDepartment = "purchase"
      } else {
        this.userDepartment = ""
      }
      if (this.type == 6){
        this.company_kind_label = '公司类别 *'
      }else {
        this.company_kind_label = '公司类别'
      }
      for (let key in this.company) {
        delete this.company[key];
      }
      this.edit()
    },
  },
  created() {
    this.id = this.$route.query.id || 0
    this.type = this.$route.query.type || 0
    if (this.type == 3) {
      this.userDepartment = "purchase"
    } else {
      this.userDepartment = ""
    }
    if (this.type == 6){
      this.company_kind_label = '公司类别 *'
    }
    const userData = getUserData()
    this.user = userData
    //yang

    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
