<template>
  <b-card style="width: 100%"
  >
    <b-form
        @submit.prevent="refreshTable"
        @reset.prevent="resetCondition"
    >
      <b-row>
        <!--合同编号-->
        <b-col
                cols="12"
                md="3"
        >
          <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="合同编号:"
                  label-for="agreementNo"
                  label-size="sm"
          >
            <b-form-input
                    v-model="condition.agreementNo"
                    name="agreementNo"
                    class="d-inline-block mr-1"
                    size="sm"
            />
          </b-form-group>
        </b-col>
        <!--供应商 签约主体-->
        <b-col
                cols="12"
                md="3"
        >
          <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="签约主体:"
                  label-for="our_company_name"
                  label-size="sm"
          >
            <b-form-input
                    id="our_company_name"
                    size="sm"
                    v-model="ourCompanyName"
                    name="ourCompanyName"
                    @click="showSingleModal('签约主体')"
                    readonly
                    placeholder="点击搜索签约主体"
            />
          </b-form-group>
        </b-col>

        <b-col
                cols="12"
                md="3"
                v-if="!other_company_id"
        >
          <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="供应商:"
                  label-for="other_company_name"
                  label-size="sm"
          >
            <b-form-input
                    id="other_company_name"
                    size="sm"
                    v-model="otherCompanyName"
                    name="otherCompanyName"
                    @click="showSingleModal('供应商')"
                    readonly
                    placeholder="点击搜索供应商"
            />
          </b-form-group>
        </b-col>
        <!--我方负责人-->
        <b-col
                cols="12"
                md="3"
        >
          <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="我方负责人:"
                  label-for="our_manage_name"
                  label-size="sm"
          >
            <b-form-input
                    id="our_manage_name"
                    size="sm"
                    v-model="ourManageName"
                    name="ourManageName"
                    @click="showSingleModal('我方负责人')"
                    readonly
                    placeholder="点击搜索我方负责人"
            />
          </b-form-group>
        </b-col>
        <!--申请人-->
         <b-col
                  cols="12"
                  md="3"
          >
              <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="申请人:"
                      label-for="applier_name"
                      label-size="sm"
              >
                  <b-form-input
                          id="applier_name"
                          size="sm"
                          v-model="applierName"
                          name="applierName"
                          @click="showSingleModal('申请人')"
                          readonly
                          placeholder="点击搜索申请人"
                  />
              </b-form-group>
          </b-col>
        <!--审核状态-->
        <b-col
                cols="12"
                md="3"
        >
          <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="审核状态:"
                  label-for="orderStatus"
                  label-size="sm"
          >
            <v-select
                    id="status"
                    :options="getCodeOptions('contract_status')"
                    v-model="condition.status"
                    name="orderStatus"
                    class="select-size-sm"
                    placeholder="请选择审核状态"
                    :reduce="option => option.value"
            />
          </b-form-group>
        </b-col>

        <!--查询  重置-->
        <b-col
            cols="12"
            md="6"
        >
          <b-button
              variant="outline-primary"
              type="submit"
              class="mr-1"
          >
            <span class="align-right">查询</span>
          </b-button>

          <b-button
              variant="outline-primary"
              type="reset"
          >
            <span class="align-right">重置</span>
          </b-button>

        </b-col>
      </b-row>
    </b-form>

    <!--弹出框头部和尾部-->
    <b-modal
        id="modal-single"
        ok-only
        ok-title="确认"
        @ok="onSelectSingle"
        cancel-title="取消"
        centered
        size="lg"
        :title="'请选择'+modalName"
        ref="singleModal"
    >
      <!--签约主体 供应商-->
      <company-select
          ref="companySelect" :companyType="companyType" v-if="modalName === '签约主体' || modalName === '供应商' "
      />

      <!--申请人-->
      <user-select
          ref="applierSelect" :department-id="departmentId" v-else-if="'申请人' === modalName "
      />
      <!--采购负责人-->
      <user-select
          ref="ourManageNameSelect" :department-id="departmentId" v-else-if="modalName === '我方负责人' "
      />
    </b-modal>
  </b-card>
</template>

<script>
import { computed,onUnmounted, reactive, toRefs } from '@vue/composition-api'
import { getCodeOptions } from '@core/utils/filter'
import CompanySelect from '@/views/apps/company/CompanySelect'
import UserSelect from '@/views/apps/user/user-modal/UserSelect'
import store from '@/store'
//import purchaseorderStore from '@/views/apps/purchaseorder/purchaseorderStore'
import ProductSelect from '@/views/apps/product/ProductSelect'
import { useToast } from 'vue-toastification/composition'
/**/
import contractStore from '@/views/apps/contract/contractStore'

export default {
  components: {
    ProductSelect,
    UserSelect,
    CompanySelect
  },
  props: {
    other_company_id: {
      type:Number,
      default:0
    }
  },
  setup(props, { emit }) {
    const toast = useToast()
    // Register module
    if (!store.hasModule('contract')) store.registerModule('contract', contractStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('contract')) store.unregisterModule('contract')
    })

    const state = reactive({
      departmentId: 1,
      companyType: '',
      modalName: '',
      /* condition: {
         orderNo: undefined,
         productIds: undefined,
         status: undefined,
         company_id: undefined,
         supplier_id: undefined,
         purchaser_id: undefined,
         applier_id: undefined,
         status_pay: undefined,
         status_over: undefined,
       },*/
      condition: {},

      otherCompanyName: undefined, //签约主体
      ourCompanyName: undefined,//供应商
      ourManageName: undefined, //我方负责人
      applierName: undefined,   //申请人

      //ref
      singleModal: null,
      // companySelect:null,
      // applierSelect:null,
      // purchaserSelect:null,
    })
    const methods = {
      showSingleModal(modalName) {
        if (modalName === '签约主体') {
          state.companyType = 'Group'
        } else if (modalName === '供应商') {
          state.companyType = 'Supplier'
        }

        state.modalName = modalName
        state.singleModal.show()
      },
      onSelectSingle() {
        let data = {}
        switch (state.modalName) {
          case '签约主体': {
            data = this.$refs.companySelect.getSelected()
            if (data.length > 0) {
              state.condition.our_company_id = data[0].company_id
              state.ourCompanyName = data[0].company_name
            }
            break
          }
          case '供应商': {
            data = this.$refs.companySelect.getSelected()
            if (data.length > 0) {
              state.condition.other_company_id = data[0].company_id
              state.otherCompanyName = data[0].company_name
            }
            break
          }
          case '申请人': {
            data = this.$refs.applierSelect.getSelected()
            if (data.length > 0) {
              state.condition.applier_id = data[0].user_id
              state.applierName = data[0].full_name
            }
            break
          }
          case '我方负责人': {
            data = this.$refs.ourManageNameSelect.getSelected()
            if (data.length > 0) {
              state.condition.ourManageId = data[0].user_id
              state.ourManageName = data[0].full_name
            }
            break
          }
        }
      },
      refreshTable() {
        store.commit('contract/updateCondition', state.condition)
        emit('refreshTable')
      },
      resetCondition() {
        state.condition = {}
        state.otherCompanyName = undefined
        state.ourCompanyName = undefined
        state.ourManageName = undefined
        state.applierName = undefined  //申请人

        methods.refreshTable()
      },
    }
    return {
      ...toRefs(state),
      ...methods,

      //filter
      getCodeOptions
    }
  },
}
</script>

<style scoped>

</style>
