import { render, staticRenderFns } from "./DepartmentList.vue?vue&type=template&id=858dff46&scoped=true&"
import script from "./DepartmentList.vue?vue&type=script&lang=js&"
export * from "./DepartmentList.vue?vue&type=script&lang=js&"
import style0 from "./DepartmentList.vue?vue&type=style&index=0&id=858dff46&prod&lang=scss&scoped=true&"
import style1 from "./DepartmentList.vue?vue&type=style&index=1&id=858dff46&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "858dff46",
  null
  
)

export default component.exports