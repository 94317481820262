import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function contractUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'contract_id',
      label: 'ID',
      sortable: true
    },
    {
      key: 'contract_title',
      label: '协议标题',
      sortable: true
    },
    {
      key: 'agreement_no',
      label: '合同编号',
      sortable: true
    },
    // { key: 'contract_no', label: '流程编号', sortable: true },
    // { key: 'applier_id', label: '申请人ID user表', sortable: true },
    {
      key: 'applier_name',
      label: '申请人',
      sortable: true
    },
    // { key: 'applier_department_id', label: '申请人所属部门ID    根据applier_id自动带出', sortable: true },
    // { key: 'applier_department_name', label: '申请人所属部门名称', sortable: true },
    // { key: 'applier_company_id', label: '申请人所属公司ID   根据applier_id自动带出', sortable: true },
    // { key: 'applier_company_name', label: '申请人所属公司名称', sortable: true },
    // { key: 'applier_job_num', label: '申请人工号     根据applier_id自动带出', sortable: true },
    // { key: 'applier_date', label: '申请时间     当前日期，系统自动带出', sortable: true },
    // { key: 'our_company_id', label: '我方单位ID  company表中company_type为2的公司', sortable: true },
    {
      key: 'our_company_name',
      label: '我方单位名称',
      sortable: true
    },
    // { key: 'our_manage_id', label: '我方负责人ID    user表', sortable: true },
    {
      key: 'our_manage_name',
      label: '我方负责人',
      sortable: true
    },
    // { key: 'our_manage_tel', label: '我方联系人电话      根据user表自动带出', sortable: true },
    // { key: 'our_manage_email', label: '我方授权电子邮箱       根据user表自动带出', sortable: true },
    // { key: 'other_company_id', label: '对方单位ID   如果是采购协议  company表中company_type为3的公司   如果是销售协议    company表中company_type为1的公司', sortable: true },
    {
      key: 'other_company_name',
      label: '对方单位名称',
      sortable: true
    },
    // { key: 'other_contact_id', label: '对方负责人ID    contact表中的人，company_id为other_company_id', sortable: true },
    // { key: 'other_contact_name', label: '对方负责人名称', sortable: true },
    // { key: 'other_contact_tel', label: '对方负责人联系人电话      根据other_contact_id自动带出', sortable: true },
    // { key: 'other_contact_email', label: '对方授权电子邮箱       根据other_contact_id自动带出', sortable: true },
    // { key: 'agreement_type', label: '合同类型   采购协议： agreement_specific_type_CG  销售协议： agreement_specific_type_XS', sortable: true },
    // { key: 'agreement_batch', label: '合同版本  agreement_batch', sortable: true },
    // { key: 'agreement_ischanged', label: '是否有修改', sortable: true },
    // { key: 'agreement_name', label: '合同名称', sortable: true },
    // { key: 'seal_type', label: '公章类型    seal_type', sortable: true },
    // { key: 'agreement_count', label: '合同份数', sortable: true },
    // { key: 'provide_source', label: '供方来源    provide_source', sortable: true },
    // { key: 'agreement_start', label: '合同有效期开始时间', sortable: true },
    // { key: 'agreement_end', label: '合同有效期结束时间', sortable: true },
    // { key: 'category_id', label: '产品分类ID  表category', sortable: true },
    // { key: 'reference_law', label: '适用哪国法律   中国  ', sortable: true },
    // { key: 'different_idea_method', label: '争议解决方式     different_idea_method', sortable: true },
    // { key: 'arbitrate', label: '仲裁地点     arbitrate', sortable: true },
    // { key: 'arbitrate_other', label: '仲裁地点   文字内容', sortable: true },
    // { key: 'getgoods', label: '交货地点  文字内容   getgoods', sortable: true },
    // { key: 'money_flow', label: '资金流向     采购协议：supplier_money_flow_CG    销售协议：supplier_money_flow_XS', sortable: true },
    // { key: 'pick_method', label: '提货方式    CG_pickup_method', sortable: true },
    // { key: 'pick_method_page', label: '提货方式合同页码', sortable: true },
    // { key: 'agreement_pay_method', label: '采购单合同付款方式   agreement_pay_method', sortable: true },
    // { key: 'agreement_pay_days', label: '天数', sortable: true },
    // { key: 'is_deposit', label: '是否保证金', sortable: true },
    // { key: 'is_bargain', label: '是否定金', sortable: true },
    // { key: 'is_prepay', label: '是否预付款', sortable: true },
    // { key: 'is_paymentdays', label: '是否有账期', sortable: true },
    // { key: 'paymentdays', label: '账期天数', sortable: true },
    // { key: 'balancemethod', label: '结算方式：例如:分超过三个阶段付款的，请备注第二笔款、第三笔款的付款条款和时间要求。其他需要留意的亦可备注。', sortable: true },
    // { key: 'balance_page', label: '结算条款合同页码', sortable: true },
    // { key: 'otherfees', label: '其他费用收/付款描述：除了货款以外的其他需要收付的款项,如：平台保证金、履约保证金等等。', sortable: true },
    // { key: 'break_promise_percent', label: '逾期违约金比率(%/日)', sortable: true },
    // { key: 'balance_currency', label: '结算币种  人民币', sortable: true },
    // { key: 'bill_type', label: '我方收取发票类型     invoice_type', sortable: true },
    // { key: 'tax_rate', label: '税率    tax_rate', sortable: true },
    // { key: 'bill_fax_page', label: '发票和税率合同页码', sortable: true },
    // { key: 'pay_account_type', label: '付款人银行账户类型    our_company_id对应公司的银行账户信息', sortable: true },
    // { key: 'pay_bank_name', label: '付款人银行及支行名称', sortable: true },
    // { key: 'pay_bank_address', label: '付款人银行及支行地点', sortable: true },
    // { key: 'pay_account_name', label: '付款人开户名称', sortable: true },
    // { key: 'pay_account_no', label: '付款人银行账号', sortable: true },
    // { key: 'pay_isoutlands', label: '付款人是否境外', sortable: true },
    // { key: 'receive_account_type', label: '收款人银行账户类型      other_company_id对应公司的银行账户信息', sortable: true },
    // { key: 'receive_bank_name', label: '收款人银行及支行名称', sortable: true },
    // { key: 'receive_bank_address', label: '收款人银行及支行地点', sortable: true },
    // { key: 'receive_account_name', label: '收款人开户名称', sortable: true },
    // { key: 'receive_account_no', label: '收款人银行账号', sortable: true },
    // { key: 'receive_isoutlands', label: '收款人是否境外', sortable: true },
    // { key: 'memo', label: '其他信息', sortable: true },
    // { key: 'attachments', label: '', sortable: true },
    {
      key: 'status',
      label: '审核状态',
      sortable: true
    },
    // { key: 'state', label: '启用', sortable: true },
    // { key: 'create_time', label: '添加时间', sortable: true },
    // { key: 'creator', label: '添加人ID', sortable: true },
    // { key: 'update_time', label: '更新时间', sortable: true },
    // { key: 'updator', label: '更新人ID', sortable: true },
    {
      key: 'actions',
      label: '操作'
    },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('contract_id')
  const isSortDirDesc = ref(true)



  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    const condition = store.getters['contract/getCondition']

    const params = {
      start: start.value,
      limit: limit.value,
      order_by: orderBy.value,
      type:config.type,
      order_desc: isSortDirDesc.value === true ? 'desc' : '',
    }
    if (config.other_company_id !== 0) {
      params.other_company_id = config.other_company_id
    }
    Object.assign(params, condition)

    store
      .dispatch('contract/search', params)
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals

        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '采购框架协议列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,


    refetchData,

    // Extra Filters
  }
}
