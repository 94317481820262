<template>
  <b-card
    class="bankaccount-edit-wrapper"
    header="编辑信息"
  >
    <!-- form -->
    <b-form id="bankaccountForm" class="edit-form mt-2">
      <b-row>
<!--        <b-col md="4">-->
<!--          <b-form-group-->
<!--            label-cols="4"-->
<!--            label-cols-lg="4"-->
<!--            label="渠道ID"-->
<!--            label-for="company_id"-->
<!--            label-size="sm"-->
<!--            class="mb-1"-->
<!--          >-->
<!--            <b-form-input-->
<!--              id="company_id"-->
<!--              size="sm"-->
<!--              v-model="bankaccount.company_id"-->
<!--              readonly-->
<!--            >-->

<!--            </b-form-input>-->
<!--          </b-form-group>-->
<!--        </b-col>-->
        <b-col md="4">

          <b-form-group
                  label-cols="4"
                  label-cols-lg="4"
                  label="银行账户类型"
                  label-for="account_type"
                  label-size="sm"
                  class="mb-1 required"
          >
              <v-select
                      id="account_type"
                      :options="getCodeOptions('bank_account_type')"
                      :clearable="true"
                      v-model="accountType"
                      @input="changeSelect('account_type',$event)"
                      class="select-size-sm"
                      placeholder="请选择银行账户类型"
              />
<!--            <b-form-select-->
<!--                    id="account_type"-->
<!--                    size="sm"-->
<!--                    v-model="bankaccount.account_type"-->
<!--                    :options="account_typeOptions"-->
<!--            />-->
          </b-form-group>


<!--          <b-form-group-->
<!--            label-cols="3"-->
<!--            label-cols-lg="3"-->
<!--            label="银行账户类型"-->
<!--            label-for="account_type"-->
<!--            label-size="sm"-->
<!--            class="mb-1"-->
<!--          >-->
<!--            <b-form-input-->
<!--              id="account_type"-->
<!--              size="sm"-->
<!--              v-model="bankaccount.account_type"-->
<!--            />-->
<!--          </b-form-group>-->


        </b-col>
        <b-col md="4">
          <b-form-group
            label-cols="4"
            label-cols-lg="4"
            label="银行账号/收款人账号"
            label-for="account_no"
            label-size="sm"
            class="mb-1 required"
          >
            <b-form-input
              id="account_no"
              size="sm"
              v-model="bankaccount.account_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label-cols="4"
            label-cols-lg="4"
            label="账户名称/收款人户名"
            label-for="account_name"
            label-size="sm"
            class="mb-1 required"
          >
            <b-form-input
              id="account_name"
              size="sm"
              v-model="bankaccount.account_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label-cols="4"
            label-cols-lg="4"
            label="开户银行/收款行名称"
            label-for="bank_name"
            label-size="sm"
            class="mb-1 required"
          >
            <b-form-input
              id="bank_name"
              size="sm"
              v-model="bankaccount.bank_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label-cols="4"
            label-cols-lg="4"
            label="网点名称"
            label-for="bank_node_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="bank_node_name"
              size="sm"
              v-model="bankaccount.bank_node_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label-cols="4"
            label-cols-lg="4"
            label="银行网点 地址"
            label-for="bank_node_address"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="bank_node_address"
              size="sm"
              v-model="bankaccount.bank_node_address"
            />
          </b-form-group>
        </b-col>
<!--        <b-col md="4">-->
<!--          <b-form-group-->
<!--            label-cols="4"-->
<!--            label-cols-lg="4"-->
<!--            label="联行号"-->
<!--            label-for="link_account_no"-->
<!--            label-size="sm"-->
<!--            class="mb-1"-->
<!--          >-->
<!--            <b-form-input-->
<!--              id="link_account_no"-->
<!--              size="sm"-->
<!--              v-model="bankaccount.link_account_no"-->
<!--            />-->
<!--          </b-form-group>-->
<!--        </b-col>-->
        <b-col md="4">
          <b-form-group
            label-cols="4"
            label-cols-lg="4"
            label="联行号"
            label-for="swift_code"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="swift_code"
              size="sm"
              v-model="bankaccount.swift_code"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label-cols="4"
            label-cols-lg="4"
            label="是否境外"
            label-for="is_outlands"
            label-size="sm"
            class="mb-1"
          >
            <b-form-checkbox
                    id="receive_isoutlands"
                    checked="true"
                    name="check-isoutlands"
                    v-model="bankaccount.is_outlands"
                    value="1"
                    unchecked-value="0"
                    switch
                    inline
            >
            </b-form-checkbox>
<!--            <b-form-input-->
<!--              id="is_outlands"-->
<!--              size="sm"-->
<!--              v-model="bankaccount.is_outlands"-->
<!--            />-->
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label-cols="4"
            label-cols-lg="4"
            label="是否默认"
            label-for="is_default"
            label-size="sm"
            class="mb-1"
          >
            <b-form-checkbox
              id="receive_isdefault"
              name="check-button"
              v-model="bankaccount.is_default"
              value="1"
              unchecked-value="0"
              switch
              inline
            ></b-form-checkbox>

<!--            <b-form-input-->
<!--              id="is_default"-->
<!--              size="sm"-->
<!--              v-model="bankaccount.is_default"-->
<!--            />-->
          </b-form-group>
        </b-col>
<!--        <b-col md="3">-->
<!--          <b-form-group-->
<!--            label-cols="3"-->
<!--            label-cols-lg="3"-->
<!--            label="启用"-->
<!--            label-for="state"-->
<!--            label-size="sm"-->
<!--            class="mb-1"-->
<!--          >-->
<!--            <b-form-input-->
<!--              id="state"-->
<!--              size="sm"-->
<!--              v-model="bankaccount.state"-->
<!--            />-->
<!--          </b-form-group>-->
<!--        </b-col>-->
<!--        <b-col md="3">-->
<!--          <b-form-group-->
<!--            label-cols="3"-->
<!--            label-cols-lg="3"-->
<!--            label="添加时间"-->
<!--            label-for="create_time"-->
<!--            label-size="sm"-->
<!--            class="mb-1"-->
<!--          >-->
<!--            <b-form-input-->
<!--              id="create_time"-->
<!--              size="sm"-->
<!--              v-model="bankaccount.create_time"-->
<!--            />-->
<!--          </b-form-group>-->
<!--        </b-col>-->
<!--        <b-col md="3">-->
<!--          <b-form-group-->
<!--            label-cols="3"-->
<!--            label-cols-lg="3"-->
<!--            label="添加人ID"-->
<!--            label-for="creator"-->
<!--            label-size="sm"-->
<!--            class="mb-1"-->
<!--          >-->
<!--            <b-form-input-->
<!--              id="creator"-->
<!--              size="sm"-->
<!--              v-model="bankaccount.creator"-->
<!--            />-->
<!--          </b-form-group>-->
<!--        </b-col>-->
<!--        <b-col md="3">-->
<!--          <b-form-group-->
<!--            label-cols="3"-->
<!--            label-cols-lg="3"-->
<!--            label="更新时间"-->
<!--            label-for="update_time"-->
<!--            label-size="sm"-->
<!--            class="mb-1"-->
<!--          >-->
<!--            <b-form-input-->
<!--              id="update_time"-->
<!--              size="sm"-->
<!--              v-model="bankaccount.update_time"-->
<!--            />-->
<!--          </b-form-group>-->
<!--        </b-col>-->
<!--        <b-col md="3">-->
<!--          <b-form-group-->
<!--            label-cols="3"-->
<!--            label-cols-lg="3"-->
<!--            label="更新人ID"-->
<!--            label-for="updator"-->
<!--            label-size="sm"-->
<!--            class="mb-1"-->
<!--          >-->
<!--            <b-form-input-->
<!--              id="updator"-->
<!--              size="sm"-->
<!--              v-model="bankaccount.updator"-->
<!--            />-->
<!--          </b-form-group>-->
<!--        </b-col>-->
<!--                <b-col md="4">-->
<!--          <b-form-group-->
<!--            label-cols="4"-->
<!--            label-cols-lg="4"-->
<!--            label="下拉框"-->
<!--            label-for="select"-->
<!--            label-size="sm"-->
<!--            class="mb-1"-->
<!--          >-->
<!--            <v-select-->
<!--              id="select"-->
<!--              v-model="bankaccount.select"-->
<!--              :options="selectOptions"-->
<!--              class="select-size-sm"-->
<!--            />-->
<!--          </b-form-group>-->
<!--        </b-col>-->
        <b-col
          cols="12"
          class="mt-50"

        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            返回
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,BFormSelect,BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import bankaccountStore from './bankaccountStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {useRouter} from "@core/utils/utils";
import contactUseList from "@/views/apps/contact/contactUseList";
import bankaccountUseList from "@/views/apps/bankaccount/bankaccountUseList";
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import companyStore from "@/views/apps/company/companyStore";
import {getCode,getCodeOptions} from "@core/utils/filter";

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormSelect,
    BFormCheckbox,
    vSelect,
    useToast,
    AttachmentUpload,
  },
  data() {
    return {
      id: ref(0),
      bankaccount: ref({}),
      companyInformation: {},
      companyId: 0,
      accountType: {},
    }
  },
  props:{
    company_id:{
      type:Number,
      default:0,
    }
  },
  setup(props) {
    const toast = useToast()

    // Register module
    if (!store.hasModule('bankaccount')) store.registerModule('bankaccount', bankaccountStore)
    if (!store.hasModule('company')) store.registerModule('company', companyStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('bankaccount')) store.unregisterModule('bankaccount')
      if (store.hasModule('company')) store.unregisterModule('company')
    })

    const edit = function() {
      store.dispatch('bankaccount/edit', {id: this.id}).then(res => {
        this.bankaccount = res.data.data;
        this.accountType = getCode('bank_account_type', this.bankaccount.account_type)
      })
    }

    const view = function() {
      store.dispatch('bankaccount/view', {id: this.id}).then(res => {
        this.bankaccount = res.data.data
      })
    }

    const cancel = function() {
        this.$router.push({name: 'apps-company-supplier-edit', query: {id:this.companyId}});
      //this.$router.go(-1)
    }

    const save = function() {
      if (this.companyId==0){
        this.bankaccount.company_id = this.company_id
      }
      if (this.company_id==0){
        this.bankaccount.company_id = this.companyId
      }
      // this.bankaccount.company_id = this.companyId
      if (this.bankaccount.account_type===undefined||this.bankaccount.account_type===""){
        toast.error("银行账户类型不能为空！")
        return false
      }

      if (this.bankaccount.account_no===undefined||this.bankaccount.account_no===""){
        toast.error("银行账号/收款人账号不能为空！")
        return false
      }

      if (this.bankaccount.account_name===undefined||this.bankaccount.account_name===""){
        toast.error("账户名称/收款人户名不能为空！")
        return false
      }

      if (this.bankaccount.bank_name===undefined||this.bankaccount.bank_name===""){
        toast.error("开户银行/收款行名称不能为空！")
        return false
      }

      if (this.bankaccount.is_outlands===undefined||this.bankaccount.is_outlands===""){
        this.bankaccount.is_outlands=0
      }

      if (this.bankaccount.is_default===undefined||this.bankaccount.is_default===""){
        this.bankaccount.is_default=0
      }

      store.dispatch('bankaccount/save', this.bankaccount).then(res => {
        if (res.data.code==0){
          toast.success('银行账户数据已保存!')
          this.bankaccount = res.data.data
          // this.bankaccount=res.data
          // if (this.companyId!=0){
          //   this.$router.push({ name: 'apps-bankaccount-list',query:{id:this.companyId}});
          // }
        }else {
          toast.error(res.data.data)
        }


      })
    }

    const router = useRouter()

    const changeSelect = function(key,event) {
      this.bankaccount[key] = event.value
    }

    const onUploaded = function (id, attachment) {
      this.bankaccount[id] = attachment.attachment_id
    }

    const getCompany = function () {
      store.dispatch('company/view', {id: this.id}).then(res => {
        this.companyInformation = res.data.data
      })
    }

    return {
      edit,
      view,
      cancel,
      save,
      getCodeOptions,

      changeSelect,
      onUploaded,

      getCompany

    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.companyId = this.$route.query.companyId || 0;
    this.edit()
    this.getCompany()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>