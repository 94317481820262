<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="searchKey"
                  class="d-inline-block mr-1"
                  placeholder="搜索关键字..."
              />
              <b-button
                  variant="primary"
                  :to="{ name: 'apps-warehousestatement-edit',query:{companyId: this.id}}"
                  class="mr-1"
              >
                <span class="text-nowrap">添加</span>
              </b-button>
              <b-button
                  variant="primary"
                  :to="{ name: 'apps-company-channel-list',query:{type: 6}}"
              >
                <span class="text-nowrap">返回</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #cell(id)="data">
          <b-link
              :to="{ name: 'apps-warehousestatement-edit', query: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
          >
            #{{ data.item.id }}
          </b-link>
        </template>

        <!-- Column: Type -->
        <template #cell(statement_type)="data">
          {{ getCodeLabel('statement_type', data.item.statement_type) }}
        </template>

        <template #cell(pay_period)="data">
          {{ getCodeLabel('preorder_pay_preiod', data.item.pay_period) }}
        </template>

        <template #cell(statement_day)="data">
          <div v-if="data.item.statement_type==2">
            次月{{ data.item.statement_day }}日
          </div>
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
              @click="changeState(data.item)"
          >
            <b-badge
                pill
                :variant="`light-$ {getCodeColor('state', data.item.state)}`"

            >
              {{ getCodeLabel('state', data.item.state) }}
            </b-badge>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >

            <template #button-content>
              <!--多菜单-->
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-warehousestatement-view', query: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">浏览</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'apps-warehousestatement-edit', query: { id: data.item.id,warehouseId: id } }">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="isEmpty(data.item.bbm_credit_id)" @click="pushCredit(data.item)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">推送帐期</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="!isEmpty(data.item.bbm_credit_id)" @click="updateCredit(data.item)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">更新帐期</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor,isEmpty} from '@core/utils/filter'
import warehousestatementUseList from './warehousestatementUseList'
import warehousestatementStore from './warehousestatementStore'
import {useRouter} from "@core/utils/utils";
import companyStore from "@/views/apps/company/companyStore";
import {useToast} from "vue-toastification/composition";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  methods: {
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('warehousestatement/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
  },
  data() {
    return {
      id: 0,
      companyInformation: {},
      selected: [],
    }
  },
  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('warehousestatement')) store.registerModule('warehousestatement', warehousestatementStore)
    if (!store.hasModule('company')) store.registerModule('company', companyStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('warehousestatement')) store.unregisterModule('warehousestatement')
      if (store.hasModule('company')) store.unregisterModule('company')
    })

    const router = useRouter()

    const getCompany = function () {
      store.dispatch('company/view', {id: this.id}).then(res => {
        this.companyInformation = res.data.data
      })
    }

    const pushCredit = function(data) {
      this.$swal({
        title: '是否推送帐期?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          store.dispatch('company/pushCredit', {companyId:data.company_id}).then(res => {
            if (res.data.code == 0) {
              //弹窗
              this.$swal({
                icon: 'success',
                title: res.data.data,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              //重新加载表格
              refetchData()
            } else {
              toast.error(res.data.data)
            }
          })
        }
      })
    }

    const updateCredit = function(data) {
      this.$swal({
        title: '是否更新帐期?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          store.dispatch('company/updateCredit', {companyId:data.company_id}).then(res => {
            if (res.data.code == 0) {
              //弹窗
              this.$swal({
                icon: 'success',
                title: res.data.data,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              //重新加载表格
              refetchData()
            } else {
              toast.error(res.data.data)
            }
          })
        }
      })
    }


    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = warehousestatementUseList(router.route.value.query.id)

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      pushCredit,
      updateCredit,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      isEmpty,
      // UI
      toTime,
      toDate,
      getCompany,
    }
  },
  created() {
    this.id = this.$route.query.id || 0
    this.companyId = this.$route.query.companyId || 0
    this.getCompany()
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
