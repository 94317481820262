<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  variant="primary"
                  :to="{ name: 'apps-contract-purchase-edit',query:{type:1}}"
              >
                <span class="text-nowrap">添加</span>
              </b-button>

              <!--杨-->
              <b-button
                      variant="primary"
                      style="margin-left: 5px"
                      @click="imminentContract"
                      v-if="false"
              >
                <span class="text-nowrap">协议即将过期提醒</span>
              </b-button>
            </div>
          </b-col>

          <search @refreshTable=refetchData :other_company_id="company_id"/>
        </b-row>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #cell(contract_id)="data">
          <!--          <b-link-->
          <!--              :to="{name: jump(data), query: { id: data.item.id }}"-->
          <!--              class="font-weight-bold d-block text-nowrap"-->
          <!--          >-->
          <!--            -->
          <!--          </b-link>-->
          #{{ data.item.contract_id }}
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
              @click="changeState(data.item)"
          >
            <b-badge
                pill
                :variant="`light-${resolveStateCode(data.item.state).color}`"

            >
              {{ resolveStateCode(data.item.state).label }}
            </b-badge>
          </b-link>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('contract_status',data.item.status)}`"
          >
            {{ getCodeLabel('contract_status', data.item.status) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div v-if="level==1||level==2||userid===data.item.applier_id||userid===data.item.our_manage_id">
            <b-dropdown
                    variant="link"
                    no-caret
                    right
            >
              <template #button-content>
                <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                />
              </template>

              <b-dropdown-group id="notRequire"
                                v-if="data.item.our_company_name && data.item.our_company_name.startsWith('VMI')"
              >
                <b-dropdown-item v-if="oaMenu.edit.includes(data.item.status)"
                                 @click="VMICommit(data.item.contract_id,2)"
                >
                  <feather-icon icon="EditIcon"/>
                  <span class="align-middle ml-50">提交审核</span>
                </b-dropdown-item>
              </b-dropdown-group>

              <oa-component v-else :model="data.item" url="contract-purchase" table="contract" :contract-type="type"
                            @refreshTable=refetchData
              ></oa-component>


              <b-dropdown-item v-if="oaMenu.edit.includes(data.item.status)"
                               :to="{ name: 'apps-contract-purchase-edit', query: { id: data.item.id,type:type },params:{readonly:0} }"
              >
                <feather-icon icon="EditIcon"/>
                <span class="align-middle ml-50">编辑</span>
              </b-dropdown-item>

              <b-dropdown-item
                      :to="oaMenu.changing.includes(data.item.status) ? { name: 'apps-contract-purchase-view', query: { id: data.item.id,type:type} }
                :{ name: 'apps-contract-purchase-edit', query: { id: data.item.id,type:type },params:{readonly:1} }"
              >
                <feather-icon icon="FileTextIcon"/>
                <span class="align-middle ml-50">查看</span>
              </b-dropdown-item>
              <!--            <b-dropdown-item
                              v-if="oaMenu.changing.includes(data.item.status)"
                              :to="{ name: 'apps-contract-purchase-edit', query: { id: data.item.id,type:type,readonly:2, } }"
                          >
                            <feather-icon icon="FileTextIcon"/>
                            <span class="align-middle ml-50">查看变更前协议</span>
                          </b-dropdown-item>-->
            </b-dropdown>
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import store from '@/store'
import { ref, watch, computed, onUnmounted, reactive, toRefs } from '@vue/composition-api'
import { avatarText, getCode, getCodeColor, getCodeLabel, getCodeOptions } from '@core/utils/filter'
import contractUseList from './contractUseList'
import contractStore from './contractStore'
import { useRouter } from '@core/utils/utils'
import { getUserData } from '@/auth/utils'
import { oaMenu } from '@/views/apps/oa/oaMenu'
import OaComponent from '@/views/apps/oa/OaComponent'
import Search from '@/views/apps/contract/search'

export default {
  components: {
    Search,
    OaComponent,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  methods: {
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('contract/state', {
        id: data.id,
        state: state
      })
          .then(res => {
            data.state = state
          })
    },
    VMICommit(id, status) {
      store.dispatch('contract/changeStatus', {
        id: id,
        status: status
      })
          .then(res => {
            this.refetchData()
          })
    }
  },
 /* data() {
    return {
      type: 0,
    }
  },*/
  props: {
    company_id: {
      type: Number,
      default: 0,
    },
    contract_type: {
      type: Number,
    }
  },
  data(){
    return{
      userid:0,
      level:0,
    }
  },
  setup(props) {
    // Register module
    if (!store.hasModule('contract')) store.registerModule('contract', contractStore)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('contract')) store.unregisterModule('contract')
    })

    const jump = function (res) {
      if (res.item.agreement_type === 1 || res.item.agreement_type === 0 || res.item.agreement_type === 2 || res.item.agreement_type === 3) {
        return 'apps-contract-edit'
      }
      if (res.item.agreement_type === 4) {
        return 'apps-contract-edit-xs'
      }
    }

    const router = useRouter()

    const imminentContract = function () {
      store.dispatch('contract/imminentContract')
              .then(res => {
                if (res.data.code==0){
                  toast.success('数据已保存!')
                }else {
                  toast.error("保存失败!")
                }
              })
    }


    const state = reactive({
      type:1
    })

    state.type = router.route.value.query.type
    if (props.contract_type) {
      state.type = props.contract_type
    }



    let {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      searchType,
      refetchData,

      // UI
      resolveStateCode,
    } = contractUseList({
      type: state.type,
      other_company_id: props.company_id
    })

    return {
      ...toRefs(state),
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      searchType,
      refetchData,
      jump,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      imminentContract,
      // UI
      resolveStateCode,
      oaMenu
    }
  },
  watch: {
    $route(to, from) {
      this.type = this.$route.query.type
      location.reload()
    }
  },
  created() {
    const userData = getUserData()
    this.userid = userData.user_id
    this.level = userData.level
    // this.type = this.$route.query.type || 0
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
