<template>
  <b-card>
    <b-form id="ContactForm" class="edit-form mt-2">
      <!--基本信息-->
      <b-card header="基本信息">
        <b-row
            v-for="(item, index) in items"
            :id="item.id"
            :key="item.id">
          <!--姓名-->
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="姓名"
                label-for="name"
                label-size="sm"
                class="mb-1 required"
            >
              <b-form-input
                  id="name"
                  size="sm"
                  v-model="item.name"
              />
            </b-form-group>
          </b-col>
          <!--职位-->
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="职位"
                label-for="position"
                label-size="sm"
                class="mb-1 required"
            >
              <v-select
                  id="position"
                  :options="getCodeOptions('company_position')"
                  :clearable="true"
                  v-model="item.position"
                  class="select-size-sm top-level"
                  placeholder="请选择职位"
                  :reduce="option => option.value"
              />
            </b-form-group>
          </b-col>
          <!--邮箱-->
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="邮箱"
                label-for="email"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="email"
                  size="sm"
                  v-model="item.email"
              />
            </b-form-group>
          </b-col>
          <!--电话-->
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="电话"
                label-for="mobile"
                label-size="sm"
                class="mb-1 required"
            >
              <b-form-input
                  id="mobile"
                  size="sm"
                  v-model="item.mobile"
              />
            </b-form-group>
          </b-col>
          <!--地址-->
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="地址"
                label-for="address"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="address"
                  size="sm"
                  v-model="item.address"
              />
            </b-form-group>
          </b-col>
          <!--是否默认-->
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="是否默认"
                label-for="is_default"
                label-size="sm"
                class="mb-1 required"
            >
              <v-select
                  id="is_default"
                  class="select-size-sm"
                  :options="getCodeOptions('yesno')"
                  v-model="item.is_default"
                  :reduce="option => option.value"
                  placeholder="请选择是否默认"
              />
            </b-form-group>
          </b-col>

          <b-col
              cols="12"
              md="12"
              class=" demo-inline-spacing d-flex align-items-center justify-content-end"
          >
            <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mt-0 mt-md-2"
                @click="removeItem(index)"
            >
              <feather-icon
                  icon="XIcon"
                  class="mr-25"
              />
              <span>删除</span>
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>
        <b-button
            variant="outline-primary"
            @click="repeateAgain"
        >
          <feather-icon
              icon="PlusIcon"
              class="mr-1"
          />
          <span>添加联系人</span>
        </b-button>
      </b-card>

    </b-form>

    <b-button
        variant="primary"
        class="mr-1"
        @click="save"
    >
      保存
    </b-button>
    <b-button
        variant="outline-secondary"
        class="mr-1"
        @click="cancel"
    >
      返回
    </b-button>
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import store from "@/store";
import {useToast} from 'vue-toastification/composition'
import axios from '@axios'
import ModalSelect from "@/views/components/modal/ModalSelect";
import {
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  addNumber,
  isEmpty
} from '@core/utils/filter'
import companyStore from "@/views/apps/company/companyStore";
import contactStore from "@/views/apps/contact/contactStore";
import Ripple from "vue-ripple-directive";
import {heightTransition} from "@core/mixins/ui/transition";

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    addNumber,
    isEmpty,
    Number,
    ModalSelect,
  },
  data() {
    return {
      contactArr: [],
      items: [],
      nextTodoId: 2,
    }
  },
  directives: {
    Ripple,
  },
  props: {
    company_id: Number,
  },
  setup(props) {
    const toast = useToast()

    // Register module
    if (!store.hasModule('contactstore')) store.registerModule('contactstore', contactStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('contactstore')) store.unregisterModule('contactstore')
    })

    const edit = function () {

      store.dispatch('contactstore/editNew', {company_id: props.company_id}).then(res => {
        let contactList = res.data.data.list
        if (!isEmpty(contactList)) {
          for(let i=0;i<contactList.length;i++){
            if(contactList[i].state === 1){
              contactList[i].position = contactList[i].position.toString()
              contactList[i].is_default = contactList[i].is_default.toString()
              this.items.push(contactList[i])
            }
          }
        }
      })
    }

    const cancel = function () {
      this.$router.go(-1)
    }

    const save = function () {
      this.contactArr = []
      let flag = true
      if (props.company_id == 0) {
        toast.error('请先填写 主体信息')
        flag = false
      }
      for (let i = 0; i < this.items.length; i++) {
        if (isEmpty(this.items[i].name)) {
          toast.error('请填写联系人' + (i + 1) + ' 姓名')
          flag = false
        }
        if (isEmpty(this.items[i].position)) {
          toast.error('请选择联系人' + (i + 1) + ' 职位')
          flag = false
        }
        if (isEmpty(this.items[i].mobile)) {
          toast.error('请填写联系人' + (i + 1) + ' 电话')
          flag = false
        }
        if (isEmpty(this.items[i].is_default)) {
          toast.error('请选择联系人' + (i + 1) + ' 是否默认')
          flag = false
        }

        this.contactArr.forEach(e=>{
          if(e.name == this.items[i].name){
            toast.error('联系人姓名'+e.name+'重复')
            flag = false
          }
        })
        this.items[i].company_id = props.company_id
        this.contactArr.push(this.items[i])
      }
      if (!flag) return
      axios.post('api/contact/save', {contactArr:this.contactArr,company_id:props.company_id}).then(res => {
        if (res.data.code == 0) {
          toast.success('数据已保存,可进行其他操作!')
        } else {
          toast.error(res.data.data)
        }
      })
    }


    return {
      edit,
      cancel,
      save,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      isEmpty,
      Number,
    }
  },
  created() {
    this.edit()
  },
  methods: {
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
