<template>
  <b-card
    class="department-edit-wrapper"
  >
      <!--所属公司名称-->
      <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
          <div class="demo-spacing-0">
          <b-alert
                  variant="primary"
                  show
          >
              <div class="alert-body" >
                  <span><strong>所属公司名称:{{getCodeLabel('company', this.companyId)}}</strong> </span>
              </div>
          </b-alert>
      </div>
      </b-col>
    <!-- form -->
    <b-form id="departmentForm" class="edit-form mt-2">
      <b-row>
<!--        <b-col md="3">-->
<!--          <b-form-group-->
<!--            label-cols="3"-->
<!--            label-cols-lg="3"-->
<!--            label="ID"-->
<!--            label-for="company_id"-->
<!--            label-size="sm"-->
<!--            class="mb-1"-->
<!--          >-->
<!--            <b-form-input-->
<!--              id="company_id"-->
<!--              size="sm"-->
<!--              v-model="department.company_id"-->
<!--            />-->
<!--          </b-form-group>-->
<!--        </b-col>-->
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="部门名称"
            label-for="department_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="department_name"
              size="sm"
              v-model="department.department_name"
            />
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="部门负责人名称"
              label-for="leader"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="leader"
                size="sm"
                v-model="username"
                v-b-modal.modal-select-user
                placeholder="点击搜索姓名"
                readonly=""
            />
          </b-form-group>
        </b-col>

        <b-modal
            id="modal-select-user"
            ok-only
            ok-title="确认"
            @ok="onSelectUser"
            cancel-title="取消"
            centered
            size="lg"
            title="选择姓名"
        >
          <user-select
              ref="myUserSelect">
          </user-select>
        </b-modal>

<!--        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="状态"
              label-for="state"
              label-size="sm"
              class="mb-1"
          >
            <b-form-checkbox
                name="check-button"
                switch

                id="state"
                v-model="department.state"
            ></b-form-checkbox>
          </b-form-group>
        </b-col>-->
<!--        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="添加时间"
            label-for="create_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="create_time"
              size="sm"
              v-model="department.create_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="添加人ID"
            label-for="creator"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="creator"
              size="sm"
              v-model="department.creator"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="更新时间"
            label-for="update_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="update_time"
              size="sm"
              v-model="department.update_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="更新人ID"
            label-for="updator"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="updator"
              size="sm"
              v-model="department.updator"
            />
          </b-form-group>
        </b-col>
                <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="下拉框"
            label-for="select"
            label-size="sm"
            class="mb-1"
          >
            <v-select
              id="select"
              v-model="department.select"
              :options="selectOptions"
              class="select-size-sm"
            />
          </b-form-group>
        </b-col>-->
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            返回
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import departmentStore from './departmentStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {getCodeLabel} from "@core/utils/filter";
import UserSelect from '@/views/apps/user/user-modal/UserSelect'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    vSelect,
    useToast,
    UserSelect,
  },
  data() {
    return {
      id: ref(0),
      department: ref({}),
      companyId:0,
      username:"",
    }
  },
  props:{
    company_id:{
      type:Number,
      default:0,
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('department')) store.registerModule('department', departmentStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('department')) store.unregisterModule('department')
    })

    const edit = function() {
      store.dispatch('department/edit', {id: this.id}).then(res => {
        // res.data.data['state'] = res.data.data['state'] == 1 ? true : false;
        this.department = res.data.data
        this.username=getCodeLabel("user",this.department.leader)
      })
    }

    const view = function() {
      store.dispatch('department/view', {id: this.id}).then(res => {
        this.department = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      // this.department['state'] = this.department['state'] ? 1 : 0;
      if (this.companyId==0){
        this.department.company_id = this.company_id
      }
      if (this.company_id==0){
        this.department.company_id = this.companyId
      }
      store.dispatch('department/save', this.department).then(res => {
          if(res.data.data.code === 0){
              toast.success('数据已保存!')
              this.department = res.data.data
          }else{
              this.department = ''
              toast.error(res.data.data);
          }
        if (this.company_id==0){
          // this.$router.push({ name: 'apps-department-list',query:{id:this.companyId}});
        }

      })
    }

    const onSelectUser = function (bvModalEvt) {
      let checked = this.$refs.myUserSelect.getSelected()[0];
      this.department.leader=checked.user_id
      this.username = checked.full_name
    }

    return {
      edit,
      view,
      cancel,
      save,
      onSelectUser,
      getCodeLabel,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.companyId = this.$route.query.companyId || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.custom-switch .custom-control-label::before{
  margin-top: 5px;
}
</style>
