<template>
  <b-card>
    <b-form id="BakAccForm" class="edit-form mt-2">
      <!--基本信息-->
      <b-card header="基本信息">
        <b-row
            v-for="(item, index) in items"
            :id="item.id"
            :key="item.id">
          <!--账户类型-->
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="账户类型"
                label-for="account_type"
                label-size="sm"
                class="mb-1 required"
            >
              <v-select
                  id="account_type"
                  :options="getCodeOptions('bank_account_type')"
                  :clearable="true"
                  v-model="item.account_type"
                  class="select-size-sm"
                  :reduce="option => option.value"
                  placeholder="请选择账户类型"
              />
            </b-form-group>
          </b-col>
          <!--是否默认-->
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="是否境外"
                label-for="is_outlands"
                label-size="sm"
                class="mb-1 required"
            >
              <v-select
                  id="is_outlands"
                  class="select-size-sm"
                  :options="getCodeOptions('yesno')"
                  v-model="item.is_outlands"
                  :reduce="option => option.value"
                  placeholder="请选择是否境外"
              />
            </b-form-group>
          </b-col>
          <!--银行账号-->
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="银行账号"
                label-for="account_no"
                label-size="sm"
                class="mb-1 required"
            >
              <b-form-input
                  id="account_no"
                  size="sm"
                  v-model="item.account_no"
              />
            </b-form-group>
          </b-col>
          <!--账户名称-->
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="账户名称"
                label-for="account_name"
                label-size="sm"
                class="mb-1 required"
            >
              <b-form-input
                  id="account_name"
                  size="sm"
                  v-model="item.account_name"
              />
            </b-form-group>
          </b-col>
          <!--银行名称-->
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="银行名称"
                label-for="bank_name"
                label-size="sm"
                class="mb-1 required"
            >
              <b-form-input
                  id="bank_name"
                  size="sm"
                  v-model="item.bank_name"
              />
            </b-form-group>
          </b-col>
          <!--支行名称-->
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="支行名称"
                label-for="bank_node_name"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="bank_node_name"
                  size="sm"
                  v-model="item.bank_node_name"
              />
            </b-form-group>
          </b-col>
          <!--银行地址-->
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="银行地址"
                label-for="bank_node_address"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="bank_node_address"
                  size="sm"
                  v-model="item.bank_node_address"
              />
            </b-form-group>
          </b-col>
          <!--结算币种-->
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="结算币种"
                label-for="currency"
                label-size="sm"
                class="mb-1"
            >
              <v-select
                  id="currency"
                  :options="currencyArr"
                  :clearable="true"
                  v-model="item.currency"
                  class="select-size-sm"
                  :reduce="option => option.value"
                  placeholder="请选择结算币种"
              />
            </b-form-group>
          </b-col>
          <!--SWIFT CODE-->
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="SWIFT CODE"
                label-for="swift_code"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="swift_code"
                  size="sm"
                  v-model="item.swift_code"
              />
            </b-form-group>
          </b-col>

          <b-col
              cols="12"
              md="12"
              class=" demo-inline-spacing d-flex align-items-center justify-content-end"
          >
            <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mt-0 mt-md-2"
                @click="removeItem(index)"
            >
              <feather-icon
                  icon="XIcon"
                  class="mr-25"
              />
              <span>删除</span>
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>

        </b-row>
        <b-button
            variant="outline-primary"
            @click="repeateAgain"
        >
          <feather-icon
              icon="PlusIcon"
              class="mr-1"
          />
          <span>添加银行账户</span>
        </b-button>
      </b-card>
    </b-form>

    <b-button
        variant="primary"
        class="mr-1"
        @click="save"
    >
      保存
    </b-button>
    <b-button
        variant="outline-secondary"
        class="mr-1"
        @click="cancel"
    >
      返回
    </b-button>
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import store from "@/store";
import {useToast} from 'vue-toastification/composition'
import axios from '@axios'
import ModalSelect from "@/views/components/modal/ModalSelect";
import {
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  addNumber,
  isEmpty
} from '@core/utils/filter'
import bankaccountStore from "@/views/apps/bankaccount/bankaccountStore";
import Ripple from "vue-ripple-directive";
import {heightTransition} from "@core/mixins/ui/transition";

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    addNumber,
    isEmpty,
    Number,
    ModalSelect,
  },
  data() {
    return {
      bankAccountArr: [],
      items: [],
      nextTodoId: 2,
      currencyArr:[{value:'1',label:'CNY'}],
    }
  },
  directives: {
    Ripple,
  },
  props: {
    company_id: Number,
  },
  setup(props) {
    const toast = useToast()

    // Register module
    if (!store.hasModule('bankaccountstore')) store.registerModule('bankaccountstore', bankaccountStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('bankaccountstore')) store.unregisterModule('bankaccountstore')
    })

    const edit = function () {

      store.dispatch('bankaccountstore/editNew', {company_id: props.company_id}).then(res => {
        let bankAccList = res.data.data.list
        if (!isEmpty(bankAccList)) {
          for(let i=0;i<bankAccList.length;i++){
            if(bankAccList[i].state === 1){
              bankAccList[i].is_outlands = bankAccList[i].is_outlands.toString()
              bankAccList[i].account_type = bankAccList[i].account_type.toString()
              this.items.push(bankAccList[i])
            }
          }
        }
      })
    }


    const cancel = function () {
      this.$router.go(-1)
    }

    const save = function () {
      this.bankAccountArr = []

      let flag = true
      if (props.company_id == 0) {
        toast.error('请先填写 主体信息')
        flag = false
      }
      for (let i = 0; i < this.items.length; i++) {
        if (isEmpty(this.items[i].account_type)) {
          toast.error('请选择 账户类型')
          flag = false
        }
        if (isEmpty(this.items[i].is_outlands)) {
          toast.error('请选择 是否境外')
          flag = false
        }
        if (isEmpty(this.items[i].account_no)) {
          toast.error('请填写 银行账号')
          flag = false
        }
        if (isEmpty(this.items[i].account_name)) {
          toast.error('请填写 账户名称')
          flag = false
        }
        if (isEmpty(this.items[i].bank_name)) {
          toast.error('请填写 银行名称')
          flag = false
        }

        this.bankAccountArr.forEach(e=>{
          if(e.account_no == this.items[i].account_no){
            toast.error('银行账号'+e.account_no+'重复')
            flag = false
          }
        })
        this.items[i].company_id = props.company_id
        this.bankAccountArr.push(this.items[i])
      }
      if (!flag) return

      axios.post('api/bankaccount/save', {bankAccountArr:this.bankAccountArr,company_id:props.company_id}).then(res => {
        if (res.data.code == 0) {
          toast.success('数据已保存,可进行其他操作!')
        } else {
          toast.error(res.data.data)
        }
      })
    }


    return {
      edit,
      cancel,
      save,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      isEmpty,
      Number,
    }
  },
  created() {
    this.edit()
  },
  methods: {
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
        currency : '1',
        is_outlands : '0',
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
